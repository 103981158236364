<template>
	<div class="home">
		<div class="iphone-nav">
			<el-row class="block-col-2">
				<el-col :span="12">
					<el-dropdown trigger="click">
						<span class="el-dropdown-link">
							<img src="@/assets/iphone-nav.png" alt="" srcset="">
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item><a onclick="window.scrollTo(0,0)">home</a></el-dropdown-item>
							<el-dropdown-item><a onclick="window.scrollTo(0,700)">about</a></el-dropdown-item>
							<el-dropdown-item><a onclick="window.scrollTo(0,1700)">tokenomics</a></el-dropdown-item>
							<el-dropdown-item><a onclick="window.scrollTo(0,2100)">roadmap</a></el-dropdown-item>
							<el-dropdown-item><a onclick="window.scrollTo(0,2550)">social</a></el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</el-col>
			</el-row>
		</div>
		<div class="header">
			<div class="rock">Rock & scRoll</div>
			<div class="box">
				<ul class="nav">
					<li><a href="#home">home</a></li>
					<li><a href="#aboutus">about</a></li>
					<li><a href="#tokenomic">tokenomics</a></li>
					<li><a href="#roadmap">roadmap</a></li>
					<li><a href="#followus">social</a></li>
				</ul>
				<div class="buynow"> <a href="https://swap-scroll.spacefi.io/#/swap" target="_block">buy now</a></div>
			</div>
			
		</div>
		<div id="home" class="banner">
			<img class="bgimg" src="@/assets/banner.png" alt="">
			<div class="bannerContent">
				<div class="bannerLeft">
					<img src="@/assets/RocknScroll.gif" alt="">
				</div>
				<div class="bannerRight">
					<p>Rock & scRoll = <img src="@/assets/rock.png" alt=""> <img src="@/assets/scrollLogo.png" alt=""></p>
					<p class="ppp">The MEME of Scroll & Ethereum Ecosystem Growth.</p>
					<p>For Fun, Probably Nothing.</p>
				</div>
			</div>
			<div class="bannerBottom">
				<div>
					<a href="https://dexscreener.com/scroll/0x877c91ac898ac71fd22c634c51e82985bcb77c64" target="_block">chart</a>
					<!-- <p></p> -->
					<p class="border"></p>
					<a href="https://swap-scroll.spacefi.io/#/swap" target="_block">buy</a>
					<!-- <p>buy</p> -->
				</div>
				
			</div>
		</div>
		<div id="aboutus" class="aboutus">
			<div class="aboutusLeft">
				<div class="aboutusTitle">
					<h1>ABOUT US</h1>
					<h1>about us</h1>
				</div>
				<div class="aboutusContent">
					<p>- Network: Scroll mainnet</p>
					<p>- Name: Rock Scroll (ROCK)</p>
					<p>- Contract (verified):</p>
					<p>0xCE3CfE8781E6dD5EE185d63100236743469acfA1</p>
					<p>- Allocation:</p>
					<p>20% for airdrop</p>
					<p>80% for initial liquidity and the LP burned</p>
					<p>It's a decentralized MEME experiment dedicated to being the symbol of Scroll community, starting from 1 ETH FDV and growing with the ecosystem.</p>
				</div>
			</div>
			<div class="aboutusRight">
				<div class="gif">
					<img src="@/assets/aboutus.gif" alt="">
					<img src="@/assets/smiley.png" alt="">
					<div class="shadow"></div>
				</div>
				<div class="link">
					<img src="@/assets/linkimg.png" alt="">
					<img src="@/assets/thumb.png" alt="">
					<div>
						<p>https://twitter.com/SandyPeng1/status/1742425376549376209</p>
						<a href="https://twitter.com/SandyPeng1/status/1742425376549376209" target="_block"><img src="@/assets/click.png" alt=""></a>
					</div>
					<div class="shadow"></div>
				</div>
			</div>
		</div>
		<div id="tokenomic" class="tokenomic">
			<div class="tokenomicTop">
				<div class="tokenomicContent">
					<div class="symbol">
						<p class="fff">SYMBOL</p>
						<p class="yellow">$ROCK</p>
					</div>
					<div class="tax">
						<p class="fff">TAX</p>
						<p class="yellow">0/0</p>
					</div>
				</div>
				<div class="tokenomicTitle">
					<h1>TOKENOMICS</h1>
					<h1>tokenomics</h1>
				</div>
			</div>
			<div class="tokenomicBottom">
				<div class="tokenomicContent">
					<div class="totalsupply">
						<p class="fff">TOTAL SUPPLY</p>
						<p class="yellow">100,000,000,000,000</p>
					</div>
				</div>
			</div>
		</div>
		<div id="roadmap" class="roadmap">
			<div class="roadmapTop">
				<div class="roadmapTitle">
					<h1>ROADMAP</h1>
					<h1>roadmap</h1>
				</div>
			</div>
			<div class="roadmapBottom">
				<div class="roadmapContent">
					<div class="initialLaunch">
						<p>INITIAL LAUNCH: AIRDROP</p>
						<img src="@/assets/greenCheck.png" alt="">
					</div>
					<div class="community">
						<p>COMMUNITY AND LIQUIDITY GROWTH</p>
						<img src="@/assets/yellowCheck1.png" alt="">
					</div>
				</div>
				<div class="roadmapContent">
					<div class="unique">
						<p>UNIQUE MEME OF SCROLL AND ETHEREUM</p>
						<img src="@/assets/yellowCheck2.png" alt="">
					</div>
				</div>
			</div>
		</div>
		<div id="followus" class="followus">
			<div class="followusBg">
				<div class="followusTitle">
					<h1>FOLLOW US</h1>
					<h1>follow us</h1>
				</div>
				<ul>
					<li><a href="https://zealy.io/c/rocknscroll/questboard" target="_block"><img src="@/assets/zealy.png" alt=""></a></li>
					<li><a href="https://twitter.com/rockscroll_io" target="_block"><img src="@/assets/twitter.png" alt=""></a></li>
					<li><a href="https://t.me/rockscroll_io" target="_block"><img src="@/assets/tg.png" alt=""></a></li>
					<li><a href="https://discord.gg/nWzq9p9Cgt" target="_block"><img src="@/assets/discord.png" alt=""></a></li>
				</ul>
			</div>
		</div>
		<div class="footer">
			<div>
				<img src="@/assets/RocknScroll.gif" alt="">
			</div>
			<p>Rock & scRoll = <img src="@/assets/rock.png" alt=""> <img src="@/assets/scrollLogo.png" alt=""></p>
			<p>The MEME of Scroll & Ethereum Ecosystem Growth.</p>
			<p>For Fun, Probably Nothing.</p>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Home',
	data() {
		return {
			navIndex: 0,
		}
	},
}
</script>
