var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"iphone-nav"},[_c('el-row',{staticClass:"block-col-2"},[_c('el-col',{attrs:{"span":12}},[_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('span',{staticClass:"el-dropdown-link"},[_c('img',{attrs:{"src":require("@/assets/iphone-nav.png"),"alt":"","srcset":""}})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',[_c('a',{attrs:{"onclick":"window.scrollTo(0,0)"}},[_vm._v("home")])]),_c('el-dropdown-item',[_c('a',{attrs:{"onclick":"window.scrollTo(0,700)"}},[_vm._v("about")])]),_c('el-dropdown-item',[_c('a',{attrs:{"onclick":"window.scrollTo(0,1700)"}},[_vm._v("tokenomics")])]),_c('el-dropdown-item',[_c('a',{attrs:{"onclick":"window.scrollTo(0,2100)"}},[_vm._v("roadmap")])]),_c('el-dropdown-item',[_c('a',{attrs:{"onclick":"window.scrollTo(0,2550)"}},[_vm._v("social")])])],1)],1)],1)],1)],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"rock"},[_vm._v("Rock & scRoll")]),_c('div',{staticClass:"box"},[_c('ul',{staticClass:"nav"},[_c('li',[_c('a',{attrs:{"href":"#home"}},[_vm._v("home")])]),_c('li',[_c('a',{attrs:{"href":"#aboutus"}},[_vm._v("about")])]),_c('li',[_c('a',{attrs:{"href":"#tokenomic"}},[_vm._v("tokenomics")])]),_c('li',[_c('a',{attrs:{"href":"#roadmap"}},[_vm._v("roadmap")])]),_c('li',[_c('a',{attrs:{"href":"#followus"}},[_vm._v("social")])])]),_c('div',{staticClass:"buynow"},[_c('a',{attrs:{"href":"https://swap-scroll.spacefi.io/#/swap","target":"_block"}},[_vm._v("buy now")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner",attrs:{"id":"home"}},[_c('img',{staticClass:"bgimg",attrs:{"src":require("@/assets/banner.png"),"alt":""}}),_c('div',{staticClass:"bannerContent"},[_c('div',{staticClass:"bannerLeft"},[_c('img',{attrs:{"src":require("@/assets/RocknScroll.gif"),"alt":""}})]),_c('div',{staticClass:"bannerRight"},[_c('p',[_vm._v("Rock & scRoll = "),_c('img',{attrs:{"src":require("@/assets/rock.png"),"alt":""}}),_vm._v(" "),_c('img',{attrs:{"src":require("@/assets/scrollLogo.png"),"alt":""}})]),_c('p',{staticClass:"ppp"},[_vm._v("The MEME of Scroll & Ethereum Ecosystem Growth.")]),_c('p',[_vm._v("For Fun, Probably Nothing.")])])]),_c('div',{staticClass:"bannerBottom"},[_c('div',[_c('a',{attrs:{"href":"https://dexscreener.com/scroll/0x877c91ac898ac71fd22c634c51e82985bcb77c64","target":"_block"}},[_vm._v("chart")]),_c('p',{staticClass:"border"}),_c('a',{attrs:{"href":"https://swap-scroll.spacefi.io/#/swap","target":"_block"}},[_vm._v("buy")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutus",attrs:{"id":"aboutus"}},[_c('div',{staticClass:"aboutusLeft"},[_c('div',{staticClass:"aboutusTitle"},[_c('h1',[_vm._v("ABOUT US")]),_c('h1',[_vm._v("about us")])]),_c('div',{staticClass:"aboutusContent"},[_c('p',[_vm._v("- Network: Scroll mainnet")]),_c('p',[_vm._v("- Name: Rock Scroll (ROCK)")]),_c('p',[_vm._v("- Contract (verified):")]),_c('p',[_vm._v("0xCE3CfE8781E6dD5EE185d63100236743469acfA1")]),_c('p',[_vm._v("- Allocation:")]),_c('p',[_vm._v("20% for airdrop")]),_c('p',[_vm._v("80% for initial liquidity and the LP burned")]),_c('p',[_vm._v("It's a decentralized MEME experiment dedicated to being the symbol of Scroll community, starting from 1 ETH FDV and growing with the ecosystem.")])])]),_c('div',{staticClass:"aboutusRight"},[_c('div',{staticClass:"gif"},[_c('img',{attrs:{"src":require("@/assets/aboutus.gif"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/smiley.png"),"alt":""}}),_c('div',{staticClass:"shadow"})]),_c('div',{staticClass:"link"},[_c('img',{attrs:{"src":require("@/assets/linkimg.png"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/thumb.png"),"alt":""}}),_c('div',[_c('p',[_vm._v("https://twitter.com/SandyPeng1/status/1742425376549376209")]),_c('a',{attrs:{"href":"https://twitter.com/SandyPeng1/status/1742425376549376209","target":"_block"}},[_c('img',{attrs:{"src":require("@/assets/click.png"),"alt":""}})])]),_c('div',{staticClass:"shadow"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tokenomic",attrs:{"id":"tokenomic"}},[_c('div',{staticClass:"tokenomicTop"},[_c('div',{staticClass:"tokenomicContent"},[_c('div',{staticClass:"symbol"},[_c('p',{staticClass:"fff"},[_vm._v("SYMBOL")]),_c('p',{staticClass:"yellow"},[_vm._v("$ROCK")])]),_c('div',{staticClass:"tax"},[_c('p',{staticClass:"fff"},[_vm._v("TAX")]),_c('p',{staticClass:"yellow"},[_vm._v("0/0")])])]),_c('div',{staticClass:"tokenomicTitle"},[_c('h1',[_vm._v("TOKENOMICS")]),_c('h1',[_vm._v("tokenomics")])])]),_c('div',{staticClass:"tokenomicBottom"},[_c('div',{staticClass:"tokenomicContent"},[_c('div',{staticClass:"totalsupply"},[_c('p',{staticClass:"fff"},[_vm._v("TOTAL SUPPLY")]),_c('p',{staticClass:"yellow"},[_vm._v("100,000,000,000,000")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roadmap",attrs:{"id":"roadmap"}},[_c('div',{staticClass:"roadmapTop"},[_c('div',{staticClass:"roadmapTitle"},[_c('h1',[_vm._v("ROADMAP")]),_c('h1',[_vm._v("roadmap")])])]),_c('div',{staticClass:"roadmapBottom"},[_c('div',{staticClass:"roadmapContent"},[_c('div',{staticClass:"initialLaunch"},[_c('p',[_vm._v("INITIAL LAUNCH: AIRDROP")]),_c('img',{attrs:{"src":require("@/assets/greenCheck.png"),"alt":""}})]),_c('div',{staticClass:"community"},[_c('p',[_vm._v("COMMUNITY AND LIQUIDITY GROWTH")]),_c('img',{attrs:{"src":require("@/assets/yellowCheck1.png"),"alt":""}})])]),_c('div',{staticClass:"roadmapContent"},[_c('div',{staticClass:"unique"},[_c('p',[_vm._v("UNIQUE MEME OF SCROLL AND ETHEREUM")]),_c('img',{attrs:{"src":require("@/assets/yellowCheck2.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"followus",attrs:{"id":"followus"}},[_c('div',{staticClass:"followusBg"},[_c('div',{staticClass:"followusTitle"},[_c('h1',[_vm._v("FOLLOW US")]),_c('h1',[_vm._v("follow us")])]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"https://zealy.io/c/rocknscroll/questboard","target":"_block"}},[_c('img',{attrs:{"src":require("@/assets/zealy.png"),"alt":""}})])]),_c('li',[_c('a',{attrs:{"href":"https://twitter.com/rockscroll_io","target":"_block"}},[_c('img',{attrs:{"src":require("@/assets/twitter.png"),"alt":""}})])]),_c('li',[_c('a',{attrs:{"href":"https://t.me/rockscroll_io","target":"_block"}},[_c('img',{attrs:{"src":require("@/assets/tg.png"),"alt":""}})])]),_c('li',[_c('a',{attrs:{"href":"https://discord.gg/nWzq9p9Cgt","target":"_block"}},[_c('img',{attrs:{"src":require("@/assets/discord.png"),"alt":""}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/RocknScroll.gif"),"alt":""}})]),_c('p',[_vm._v("Rock & scRoll = "),_c('img',{attrs:{"src":require("@/assets/rock.png"),"alt":""}}),_vm._v(" "),_c('img',{attrs:{"src":require("@/assets/scrollLogo.png"),"alt":""}})]),_c('p',[_vm._v("The MEME of Scroll & Ethereum Ecosystem Growth.")]),_c('p',[_vm._v("For Fun, Probably Nothing.")])])
}]

export { render, staticRenderFns }